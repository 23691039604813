.orgbrain-style {
  font-family: Roboto;
  color: #123340;
  .title-1 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;

    padding-bottom: 20px;

    @media screen and (max-width: 640px) {
      .title-1 {
        font-size: 28px;
        line-height: 34px;
        font-weight: 400;

        padding-bottom: 10px;
      }
    }
  }
  .title-2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;

    padding-bottom: 10px;
  }

  .title-2-padding-top {
    padding-top: 30px;
  }
  .title-3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    padding-bottom: 10px;
  }

  .sub-heading-1 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 8px;
  }

  .body-text-1 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #546e7a;
    padding-bottom: 6px;
  }

  .body-text-2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #546e7a;
    padding-bottom: 4px;
  }

  .main-tab {
    .mat-tab-label .mat-tab-label-content {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  .second-tab {
    .mat-tab-label .mat-tab-label-content {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .bold {
    font-weight: 700;
  }
  .semi-bold {
    font-weight: 600;
  }
  .medium {
    font-weight: 500;
  }
  .normal {
    font-weight: 400;
  }
  .light {
    font-weight: 300;
  }
}
