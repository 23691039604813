@import '../colours';

orgbrain-defaultlayout {
  .mat-drawer-container {
    background-color: $orgbrain-gray-background;
  }

  .mat-card:not(.meetings-card) {
    padding-left: 0px;
    padding-right: 0px;
    &.no-padding {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    @media (max-width: 481px) {
      & {
        margin: 0px 0px 0px 0px;
      }

      .mat-card-header-text {
        margin: 0 5px;
      }
    }
  }

  .default-padding {
    padding: 0 24px;
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.6);
  }

  .main-container {
    width: $max-table-width;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
  }
}
