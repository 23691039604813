.marginAuto {
  margin: auto;
}

.rFlex {
  display: flex;
  flex-direction: row;
}

.cFlex {
  display: flex;
  flex-direction: column;
}

.centreAllFlex {
  align-items: center;
  justify-content: center;
}

.alignCentre {
  align-items: center;
}

.centreFlex {
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.spacebetween {
  justify-content: space-between;
}

.spacearound {
  justify-content: space-around;
}

.spaceevenly {
  justify-content: space-evenly;
}
.alignselfstart {
  align-self: start;
}

.centre {
  text-align: center;
}

.textaligncenter {
  text-align: center;
}

.mat-tooltip-trigger {
  touch-action: auto !important;
}

.mdc-tooltip {
  .mdc-tooltip__surface[class] {
    background: #0097a7;
  }
  background: #0097a7;
  font-size: 14px;
  padding: 5px !important;
  border-radius: 4px;

  overflow: visible !important;
  position: relative !important;
  overflow-wrap: break-word;
  &:hover {
    display: none;
  }
  &:not(.avoid-triangle) {
    // http://jsfiddle.net/amortka/t985yf4p/
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }

    // https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors
    &[style*='top']::before {
      top: 0%;
      left: 50%;
      margin-left: -10px;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      border-bottom: solid 10px #0097a7;
      margin-top: -10px;
    }

    &[style*='left']::before {
      left: 0%;
      top: 50%;
      margin-left: -10px;
      border-top: solid 10px transparent;
      border-right: solid 10px #0097a7;
      border-bottom: solid 10px transparent;
      margin-top: -10px;
    }

    &[style*='right']::before {
      left: 100%;
      top: 50%;
      border-left: solid 10px #0097a7;
      border-top: solid 10px transparent;
      border-bottom: solid 10px transparent;
      margin-top: -10px;
    }

    &[style*='bottom']::before {
      top: 100%;
      left: 50%;
      margin-left: -10px;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      border-top: solid 10px #0097a7;
    }
  }
}

.desc-box-highlight {
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  &:hover {
    background: #eef1f3;
    -webkit-box-shadow: 0px 0px 0px 8px #eef1f3;
    box-shadow: 0px 0px 0px 8px #eef1f3;
  }

  * {
    max-width: 100%;
  }
}
