$iconBackgroundHighLight: #a9b6bc52;
$iconDefaultGrey: #37474fde;

$black: #000;
$white: #fff;
$orgbrain-gray: #546e7a;
$orgbrain-light-gray: #b0bec5;
$orgbrain-blue: #0097a7;
$orgbrain-orange: #f57c00;
$agenda-blue-hover: #cbe4e9;
$textMediumGrey: rgb(87, 87, 87); //This is the title color #123340
$textDarkGrey: $orgbrain-gray;
$iconLightGrey: #a9b6bc;
$icontextDarkGrey: $orgbrain-gray;

// TODO: Remove this once we have a proper design system
$card-padding-left: 1px;
$card-padding-top: 1px;
$card-padding-right: 1px;
$card-padding-bottom: 1px;
$medium-card-padding-left: 1px;
$medium-card-padding-top: 1px;
$medium-card-padding-right: 1px;
$medium-card-padding-bottom: 1px;
$small-card-padding-left: 1px;
$small-card-padding-top: 1px;
$small-card-padding-right: 1px;
$small-card-padding-bottom: 1px;
$dialog-padding: 1px;
