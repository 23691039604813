.orgbrain-style {
  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .space-around {
    justify-content: space-around;
  }

  .hide-text {
    -webkit-text-security: disc;
  }

  .no-background {
    background: none !important;
  }
}
