/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */

@import 'assets/scss/main.scss';
@import 'assets/scss/global-meeting-card.scss';
@import 'assets/scss/global-agenda-suggestions-side-panel.scss';
@import 'assets/scss/global-chat-window.scss';

/* Override  the ngx-extended-annotations font. */
ngx-extended-pdf-viewer .body,
ngx-extended-pdf-viewer .pdf-viewer button,
ngx-extended-pdf-viewer .pdf-viewer input,
ngx-extended-pdf-viewer .pdf-viewer select {
  font-family: Roboto !important;
}

ngx-extended-pdf-annotation {
  .comment-top-meta-info .mygreyicon {
    top: -6px;
  }
  .comment-name-and-date {
    .name {
      font-size: 14px;
    }
    .date {
      font-size: 12px;
    }
  }
  .comment-body-brief {
    font-size: 14px;
  }
  .comment-body {
    font-size: 14px;
  }
  .comment-body-formfield {
    font-size: 14px;
  }
}
