orgbrain-chat-window {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: unset;
    align-items: center;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      padding: unset;
      padding-right: 0.25em;
      border-top: unset;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline,
  .mat-form-field-subscript-wrapper {
    display: none;
  }
}
