.agenda-suggestions-side-panel-search-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      padding-top: 0;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        border-top: 0;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
      bottom: 0;
    }
  }
}

.mat-mdc-menu-content {
  /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
  .mat-mdc-menu-item.agenda-suggestions-side-panel-mat-menu-heading {
    color: rgba(55, 71, 79, 0.87);
    font-style: italic;
  }
}

.agenda-suggestions-side-panel-flex-container {
  .suggestion-card,
  .follow-up-card {
    .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
      background-color: white;
    }
  }
}

.suggestion-card.cdk-drag-preview {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    padding-left: 32px;
    width: 217px;
    color: rgba(55, 71, 79, 0.87);
  }

  mat-pseudo-checkbox {
    display: none;
  }
}
